import React, {useEffect, useRef, useState} from 'react';
import './BobbySpin.css';
import {Box, Button, ButtonGroup, Center, Container, Heading, keyframes, Stack, Text} from "@chakra-ui/react";
import {ArrowForwardIcon} from "@chakra-ui/icons";
import {FacebookShareButton, TwitterShareButton, WhatsappShareButton} from "react-share";
import {FaFacebook, FaTwitter, FaWhatsapp} from "react-icons/fa";

const spin = keyframes`
  0% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
  50% {
    background-color: #49e819;
    box-shadow: 0 0 10px #49e819;
  }
  100% {
    background-color: #2ba805;
    box-shadow: 0 0 3px #2ba805;
  }
`;

function BobbySpinApp() {
    const videoRef = useRef(null);
    const audioRef = useRef(null);

    const [buttonHidden, setButtonHidden] = useState(false);
    const [timeSeconds, setTimeSeconds] = useState(0);
    const [running, setRunning] = useState(false);

    useEffect(() => {
        let interval: string | number | NodeJS.Timeout | undefined;
        if (running) {
            interval = setInterval(() => {
                setTimeSeconds((prevTime) => prevTime + 1);
            }, 1000);
        } else if (!running) {
            clearInterval(interval);
        }
        return () => clearInterval(interval);
    }, [running]);

    useEffect(() => {
        if (timeSeconds > 0) {
            localStorage.setItem('bsTime', timeSeconds.toString());
        }
    }, [timeSeconds]);

    useEffect(() => {
        const bsTime = localStorage.getItem('bsTime');
        if (bsTime) {
            setTimeSeconds(+bsTime);
        }
    }, []);

    const onAudioPlayed = () => {
        (videoRef?.current as any).play();
        setButtonHidden(true);
        setRunning(true);
    }

    const start = () => {
        (audioRef?.current as any).play();
    }

    const getMessage = () => `I've been watching Bobby spin for ${timeSeconds} seconds. You should too @ BobbySpin.co.uk`;

    return (
        <Container maxW="6xl">
            <Stack
                as={Box}
                textAlign={'center'}
                spacing={{base: 6, md: 6}}
                py={{base: 10, md: 10}} alignItems={"center"}>
                <Text fontSize={'lg'}>
                    Welcome To️
                </Text>
                <Heading
                    bgGradient='linear(to-l, #7928CA, #FF0080)'
                    bgClip='text'
                    fontSize='4xl'>
                    BobbySpin
                </Heading>
                <Button onClick={start} hidden={buttonHidden} size={"lg"} animation={`${spin} infinite 2s linear`}
                        rightIcon={<ArrowForwardIcon/>}
                >SPIN BOBBY SPIN</Button>
                <Box
                    as='video'
                    controls={false}
                    objectFit='contain'
                    loop
                    muted
                    playsInline
                    ref={videoRef}
                    preload="auto"
                >
                    <source src='bobs.mp4#t=0.5' type='video/mp4'/>
                    {/*<source src="bobs.webm#t=0.5" type="video/webm"/>*/}
                    {/*<source src="bobs.ogv#t=0.5" type="video/ogv"/>*/}
                </Box>
                <Text fontSize={'lg'}>
                    <div className="numbers">
                        You have been watching Bobby spin for: <Text as={'u'}>
                        {timeSeconds} seconds
                        {/*<span>{("0" + Math.floor((timeSeconds / 60) % 60)).slice(-2)}:</span>*/}
                        {/*<span>{("0" + Math.floor((timeSeconds) % 60)).slice(-2)}</span>*/}
                    </Text> ✌️
                    </div>
                </Text>
                <audio loop autoPlay src="music.mp3" onPlay={onAudioPlayed} ref={audioRef}></audio>
                <ButtonGroup spacing='2' size={"sm"}>
                    <FacebookShareButton
                        url={"https://www.bobbyspin.co.uk"}
                        quote={getMessage()}
                        hashtag="#bobbyspin">
                        <Button w={'full'} colorScheme={'facebook'} leftIcon={<FaFacebook/>}>
                            <Center>
                                <Text>Share</Text>
                            </Center>
                        </Button>
                    </FacebookShareButton>
                    <TwitterShareButton
                        url={"https://www.bobbyspin.co.uk"}
                        title={getMessage()}
                        hashtags={["bobbyspin", "neverstopspinning", "spinbobbyspin"]}
                    >
                        <Button w={'full'} colorScheme={'twitter'} leftIcon={<FaTwitter/>}>
                            <Center>
                                <Text>Tweet</Text>
                            </Center>
                        </Button>
                    </TwitterShareButton>
                    <WhatsappShareButton
                        url={"https://www.bobbyspin.co.uk"}
                        title={getMessage()}
                    >
                        <Button w={'full'} colorScheme={'whatsapp'} leftIcon={<FaWhatsapp/>}>
                            <Center>
                                <Text>Message</Text>
                            </Center>
                        </Button>
                    </WhatsappShareButton>
                </ButtonGroup>
                <Center>
                    <Text fontSize={'xs'}>Est. 2010</Text>
                </Center>
            </Stack>
        </Container>
    );
}

export default BobbySpinApp;
